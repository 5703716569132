.customBodyBg {
  background: #f7f8fa !important;
}
input.inputBg,
.ant-picker.inputBg{
  background: #FFF !important;
  font-size: 14px !important;
  line-height: 21px !important;
}
@media only screen and (max-width: 768.99px) {
  .inputAipa textarea{
    padding: 15px 20px !important;
  }
}
.ant-input-affix-wrapper.inputBg{
  background: #FFF !important;
  border: 1px solid rgba(18, 18, 73, 0.15) !important;
  background: #FFF !important;
  border-radius: 10px !important;
}
.addCaseLabel .ant-form-item-label >label{
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #11263C !important;
  /* color: #F00 !important; */
}
.addCaseLabel .ant-form-item-control-input-content {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  /* justify-content: space-evenly; */
  gap:7px;
}
.addCaseHr {
  border-top: 1px dashed #11263c;
  opacity: 0.2;
  margin-top: -3px;
}
/************* AIPA Style ************/
.aipaBody{
  width:100%;
  position: relative;
  background: #FFF url('../public/images/body-bg1.png') no-repeat center center;
	background-size: cover !important;
  min-height:100vh;
}
@media only screen and (min-width: 992.99px) {
  .aipaBody{
    background: #FFF url('../public/images/aipa-desktop-bg.png') no-repeat center center;
  }
}
.paddingPage {
  padding-bottom: 15px !important;
}
textarea.textAreaStyle {
  width:100%;
  position: relative;
  height:100%;
  box-sizing: border-box!important;
  padding: 20px 25px!important;
	font-weight: 400!important;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: -0.02em!important;
  background: rgba(255, 255, 255, 0.5) !important;
	box-shadow: 0px 0px 15px rgba(0, 114, 153, 0.05)!important;
	border-radius: 10px!important;
  min-height: 327px !important;
  max-height: 327px;
}
textarea.textAreaStyle2 {
  width:100%;
  position: relative;
  height:100%;
  box-sizing: border-box!important;
  padding: 20px 25px!important;
	font-weight: 400!important;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: -0.02em!important;
  background: rgba(255, 255, 255, 0.5) !important;
	box-shadow: 0px 0px 15px rgba(0, 114, 153, 0.05)!important;
	border-radius: 10px!important;
  min-height: 210px !important;
  max-height: 210px;
}
/************* Reclaim Style ************/
.reclaimBody{
  width:100%;
  position: relative;
  background: #e1eefc url('../public/images/reclaim-body1.svg') no-repeat center center;
	background-size: cover !important;
  min-height:100vh;
  font-family: 'Poppins', sans-serif !important;
}
.reclaimLoginBody{
  width:100%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  position: relative;
  background: #e1eefc url('../public/images/Reclaim_1.svg') no-repeat center center;  
  background-size: cover !important;
  min-height:100vh;
  font-family: 'Poppins', sans-serif !important;
}
@media only screen and (min-width: 992.99px) {
  .reclaimBody{
   
    background: #D9E8FB url('../public/images/reclaim-body1.svg') no-repeat center center;
    /* background: #FFF url('../public/images/body-bg.svg') no-repeat center center;
    background-attachment: fixed;
    background-size: cover !important; */
    position: relative;
    min-height: 100vh;
    /* background: linear-gradient(180deg, #FAFEFF 0%, #D9E8FB 100%); */
  }
}

textarea.textAreaReclaimStyle {
  width:100%;
  position: relative;
  height:100%;
  background: rgba(255, 255, 255, 0.8) !important;
  box-sizing: border-box!important;
  padding: 20px 25px!important;
	font-weight: 400!important;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: -0.02em!important;
	box-shadow: 0px 0px 15px rgba(0, 114, 153, 0.05)!important;
	border-radius: 10px!important;
  min-height: 195px !important;
  max-height: 195px;
  border-color:#DCDCDC;
  &:hover,
  &:focus{
    border-color:#DCDCDC !important;
  }
  @media only screen and (max-width: 992.99px) {
    min-height: 250px !important;
    max-height: 250px;
	}
}
.reclaimClass svg, 
.drowdownSubTitle, 
.btnRefine{
  color: #0e4c88 !important;
}
.btnRefine{
  color: #0e4c88 !important;
  filter: invert(0) !important;
}
.btnRefine img{
  filter: invert(0) !important;
}
.btnRefine:hover img{
  filter: brightness(0) invert(1) !important;
}

@media only screen and (max-width: 992.99px) { 
  /* .btnRefine{
    display: none !important;
    filter: invert(0) !important;
  } */
}
.marginNagitive{
  margin-top: -100px !important;
}
.reclaimReadMore button{
  display: block !important;
  color: #0e4c88 !important;
  text-decoration: none !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.modalBtnColor{
  background: red;;
}

.reclaimModalBtnColor{
  background: green;
}

.pinInputOTP .pincode-input-container input {
  width: 50px !important;
  height: 50px !important;
  font-size: 2rem !important;
  margin-right: 10px !important;
  padding: 5px !important;
  margin-bottom: 15px !important;
}
@media only screen and (max-width: 1236.99px) {
  .pinInputOTP .pincode-input-container input {
    width: 40px !important;
    height: 40px !important;
    font-size: 1rem !important;
    margin-right: 5px !important;
    padding: 5px !important;
  }
}
.reclaimInstHead .ant-list-header{
  background: none !important;
}

/************* Reclaim Style ************/
.reclaimRevolutionBody{
  width:100%;
  position: relative;
  background: #FFFFFF url('../public/images/reclaim-revolution-body.png') no-repeat center center;  
  background-size: cover !important;
  min-height:100vh;
  font-family: 'Poppins', sans-serif !important;
  @media only screen and (max-width: 768.99px) {
		/* background: #f8fbfd !important; */
    background: #d1e3f0 url('../public/images/reclaim-revolution-body.png') no-repeat center center;
    background-size: cover;
	}
}