.Vector {
	width: 20px;
	height: 20px;
	margin: 0 0 13px 10px;
	background-color: #fff;
}

.Notes {
	width: 52px;
	height: 27px;
	margin: 10px 72px 11px 9px;
	font-family: Poppins;
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #212226;
}

.Personal-Chat-Bubble {
	width: 200px;
	height: 64px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	gap: 4px;
	margin: 23px 17px 0 97px;
	padding: 10px;
	background-color: #676ee0;
}

.Chat-Right {
	/* width: 180px;
	height: 24px;
	flex-grow: 0; */
	font-family: Inter;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
}
.Chat-Left {
	/* width: 174px;
	height: 24px;
	flex-grow: 0; */
	font-family: Inter;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	text-align: left;
	color: #212226;
}

.Read {
	width: 58px;
	height: 16px;
	flex-grow: 0;
	font-family: Inter;
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
}

.Enter-text {
	width: 116px;
	height: 30px;
	opacity: 0.6;
	/* font-family: Inter; */
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.14;
	letter-spacing: -0.28px;
	text-align: left;
	color: #212226;
}

.Inner-Input {
	width: 264px;
	height: 50px;
	margin: 2px 10px 0 0;
	padding: 10px 124px 10px 24px;
	border-radius: 30px;
	background-color: #f7f7fc;
}
.editableDiv{
	background: #f7f7fc !important;
    border-radius: 30px !important;
    border-color: #f7f7fc;
	padding:8px 15px;
	height: 2.5rem;
	font-size: 14px;
	outline: none; 
	white-space: nowrap;
	overflow-y: auto;
}
.editableDiv[contenteditable="true"]::before {
	content: attr(aria-label); 
	color: gray;
	pointer-events: none;
	display: block; 
  }
.editableDiv[contenteditable="true"]:focus::before,
.editableDiv[contenteditable="true"]:not(:empty)::before {
	content: '';
	
}
.editableDiv:focus, .editableDiv:focus-visible{
	border:1px solid #e2e2e6 !important;
	
}
