/***********************
Timeline 03.05.2024
************************/
.tagRejected {
	background: #fff !important;
	border-radius: 4px;
	color: #d40e0e;
	margin-inline-end: 0px;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.03em;
	border: none;
}
.tagSuccess {
	background: none;
	border-radius: 4px;
	text-wrap: wrap;
	color: #1f9254;
	margin-inline-end: 0px;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.03em;
	border: none;
}
.ant-timeline-item {
	min-height: 100px;
}

/* .rejected .ant-timeline-item-tail {
	background: #d40e0e;
} */
.rejected p {
	margin: 0px;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	color: #d40e0e;
}
.success p,
.inprogress p {
	margin: 0px;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	color: #22b206 !important;
}
.pending p {
	margin: 0px;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	color: #212226 !important;
}
.success .ant-timeline-item-tail {
	background: #22b206;
}
.inprogress .ant-timeline-item-tail {
	/* top:37% !important; */
	&:after {
		content: '';
		height: 33%;
		width: 1px;
		position: absolute;
		right: 0;
		top: 0px;
		border-left: 2px dashed #22b206;
	}
}
.ant-timeline-item-tail {
	border-inline-start: 2px solid rgba(5, 5, 5, 0.06) !important;
	height: calc(100% - -10px) !important;
}
.timeLineButton {
	display: inline-block;
}
.timeLineButton button {
	background: #fff;
	border: 1px solid rgba(0, 114, 153, 0.1);
	border-radius: 5px;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: #007299;
}
.timeLineButton button:hover {
	background: #fff !important;
	color: #007299 !important;
	border-color: #4ec7eff8 !important;
}
.timeLineButton button:disabled:hover {
	background: rgba(0, 0, 0, 0.04) !important;
	border-color: #d9d9d9 !important;
	color: rgba(0, 0, 0, 0.25) !important;
}
.ant-timeline-item-content {
	inset-block-start: -5px !important;
}
.scrollAfix {
	width: 100%;
	position: relative;
	padding: 0px;
	max-height: 80vh;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;

	@media only screen and (max-width: 991.99px) {
		height: auto;
		overflow-y: inherit;
		padding: 10px;
	}
}
.scrollAfix::-webkit-scrollbar {
	width: 2px;
	height: 2px;
	background: #f4f3f3;
}
.scrollAfix::-webkit-scrollbar-thumb {
	background: #9b9b9b;
}
.scrollAfix-prepare-letter {
	width: 100%;
	position: relative;
	padding: 0px 5px 15px 0px;
	max-height: 100%;
	height: 80vh;
	overflow-y: auto;
	overflow-x: hidden;

	/* @media only screen and (max-width: 991.99px) {
		height: auto;
		overflow-y: inherit;
		padding: 10px;
	} */
}
.scrollAfix-prepare-letter::-webkit-scrollbar {
	width: 2px;
	height: 2px;
	background: #f4f3f3;
}
.scrollAfix-prepare-letter::-webkit-scrollbar-thumb {
	background: #9b9b9b;
}
.appeal-letter-modal::-webkit-scrollbar {
	width: 3px;
	height: 3px;
	background: #f4f3f3;
}
.appeal-letter-modal::-webkit-scrollbar-thumb {
	background: #9b9b9b;
}
/* .ant-timeline-item-head{
	top:35% !important;
}
.ant-timeline-item-tail{
	top:35% !important;
} */
/* .rejected .ant-timeline-item-head {
	background: #d40e0e;
	border-color:#d40e0e;
}

.success .ant-timeline-item-head {
	background: blue;
	border-color:blue;
}
.inprogress .ant-timeline-item-head {
	background: yellow;
	border-color:yellow;
}

.pending .ant-timeline-item-head {
	background: gray;
	border-color:gray;
} */
.ant-timeline-item-head-custom {
	/* padding-block: 0px !important;	 */
	/* height:0px !important */
}
.animationSlide {
	animation-name: slideDown;
	-webkit-animation-name: slideDown;

	animation-duration: 1s;
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;
	-webkit-animation-timing-function: ease;

	visibility: visible !important;
}

@keyframes slideDown {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(0%);
	}
}

/* @-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	50%{
		-webkit-transform: translateY(8%);
	}
	65%{
		-webkit-transform: translateY(-4%);
	}
	80%{
		-webkit-transform: translateY(4%);
	}
	95%{
		-webkit-transform: translateY(-2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
} */
.showDesktop {
	display: block;
}
.showMobile {
	display: none;
}
@media only screen and (max-width: 991.99px) {
	.showDesktop {
		display: none;
	}
	.showMobile {
		display: block;
	}
}
