.global-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none; /* Prevent interactions with the underlying elements */
	z-index: 9999; /* Ensure it's on top of all elements */
}

.loader-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background-color: rgba(0, 0, 0, 0.3); */
	pointer-events: all; /* Allow pointer events only for the overlay */
	cursor: not-allowed; /* Change cursor to not-allowed */
}
